import { Canvas } from '@react-three/fiber';
import ModelWrapper from './ModelWrapper';
import { OrbitControls } from '@react-three/drei';

/**
 *
 *  This is a react-thee fiber canvas container component
 *  and all childrens of this component should be of threeJs
 *
 *  the Canvas component used here can be moved to the hegher
 *  component and this component can be used as a wrapper for
 *  all the threeJS components
 *
 *  or we can have the existing ocmponent it works either way
 *
 */

const PreviewCanvas = ({ modelProps, orbitControls }) => {
  return (
    <Canvas>
      {orbitControls && <OrbitControls />}
      <ModelWrapper modelProps={modelProps} />
    </Canvas>
  );
};

export default PreviewCanvas;
