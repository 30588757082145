import { useRef } from 'react';
import { useState } from 'react';
import PreviewCanvas from '../../Components/threeJs/PreviewCanvas';
import IVP from '../../Components/Utils/InlineVideoPlayer';
import LeftUI from './LeftUI';
import RightUI from './RightUI';
import { createRecorder, recordScreen } from './recorderUtils';
// import { useModelStore } from '../../Store/modelStore';

const Room = () => {
  const c1 = useRef();
  const rec = useRef();
  const [modelProps, setModelProps] = useState({
    rotation: { x: 0, y: 0, z: 0 },
    autoRotate: false,
    scale: 1,
    orbitControls: false,
    curAnimIndex: 0,
    material: null,
  });
  const [UIProps, setUIProps] = useState({
    showUI: true,
    camera: false,
    backCamera: true,
    cameraFlip: false,
    hideThumbnails: true,
    record: false,
  });
  // const [data, setData] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const buttonStyle = `btn bg-mid rounded w-full font-bold text-text text-center p-2 ${
    UIProps.showUI ? '' : 'hidden'
  }`;
  const handleRecordClick = async () => {
    if (UIProps.record && mediaRecorder) {
      mediaRecorder.stop();
      setUIProps((up) => ({ ...up, record: false }));
      return;
    }

    const stream = await recordScreen();
    const mimeType = 'video/mp4';
    const mr = createRecorder(stream, mimeType);
    setMediaRecorder(mr);
    setUIProps((up) => ({ ...up, record: true }));
  };

  return (
    <div className='grid items-center  select-none  h-screen w-screen'>
      <div className='absolute z-30'>
        <canvas ref={c1} className='w-screen h-screen'></canvas>
      </div>
      <div
        ref={rec}
        className='relative h-screen bg-green overflow-hidden w-screen grid items-center'>
        {UIProps.camera && (
          <IVP flip={UIProps.cameraFlip} active={UIProps.camera}></IVP>
        )}
        <div className='absolute z-50 h-screen w-full top-0'>
          <PreviewCanvas
            modelProps={modelProps}
            orbitControls={modelProps.orbitControls}
          />
        </div>
      </div>
      <div className='absolute p-5 flex w-screen h-screen  justify-between gap-2 top-0'>
        <div className='flex z-50 gap-3 h-full'>
          <LeftUI
            UIProps={UIProps}
            setUIProps={setUIProps}
            buttonStyle={buttonStyle}
            handleRecordClick={handleRecordClick}
          />
        </div>
        <div className='text-3xl z-50 justify-self-end items-center flex flex-col gap-4 '>
          <RightUI
            UIProps={UIProps}
            setModelProps={setModelProps}
            buttonStyle={buttonStyle}
            modelProps={modelProps}
            setUIProps={setUIProps}
          />
        </div>
      </div>
    </div>
  );
};

export default Room;
