import React from 'react';

const Row = (props) => {
  const center = ' justify-center items-center';
  return (
    <div
      {...props}
      className={`flex gap-2 m-2 w-full  ${props.className} ${
        props.center && center
      }`}>
      {props.children}
    </div>
  );
};
const Col = (props) => {
  const center = 'justify-center items-center';

  return (
    <div
      {...props}
      className={`flex flex-col gap-2 m-2 w-full ${props.className} ${
        props.center && center
      }`}>
      {props.children}
    </div>
  );
};

const Flex = { Row, Col };
export default Flex;
