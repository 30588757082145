import React from 'react';

const Container = (props) => {
  const varients = props.varient === 'center' ? 'grid items-center' : '';
  return (
    <div className={`p-2 w-full ${varients} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Container;
