import { useEffect, useState } from 'react';
// import { useModelStore } from '../Store/modelStore.js';
import { useModelStore } from '../../Store/modelStore';
import './modelPanel.css';

/**
 *
 * This Model Panel component handles the displaying the model
 * thumbnail and rendering the model when we click on the model
 *
 *
 * @returns
 */

const ModlePanel = ({ setModelProps }) => {
  const [data, setData] = useState([]);
  const [filterdData, setFilteredData] = useState([]);

  // accessing model date from the external store
  const md = useModelStore((state) => state.modelObj);
  const {
    model,
    pushModel,
    setCurModelAnim,
    setCurentModelUrl,
    setCurrentModelData,
    curModelAnimLength,
    images,
    pushImage,
  } = useModelStore((state) => state);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchAllImages = async (json) => {
    json.forEach(async (item) => {
      if (images[item.thumbAddr]) {
        return;
      }
      const result = await fetch(
        `${SERVER_URL}models/getimage/${item.thumbAddr}`
      ).catch((err) => {
        throw err;
      });
      if (!result) {
        return;
      }
      const json = await result.json();
      const imageName = json.Key.split('/')[1];
      pushImage({ name: imageName, blob: json.bufferData.data });
    });
    fetchAllTheModels(json);
  };

  const fetchAllTheModels = async (json) => {
    await json.forEach(async (data) => {
      /**
       *  this below condition checks if the model exists
       *  in the mdoel store
       */

      if (model[`models_${data.fileAddr?.split('/')[1]}`]) {
        return;
      }
      const result = await fetch(`${SERVER_URL}models/get/${
        data.fileAddr.split('/')[1]
      }
      `)
        .catch((err) => {
          throw err;
        })
        .catch((err) => {
          console.error(err);
        });
      if (!result) {
        return;
      }
      const json = await result.json();
      pushModel({
        name: json.Key.replace('/', '_'),
        blob: json.bufferData.data,
      });
    });
  };

  const fetchData = async () => {
    const localdata = localStorage.getItem('tutar-model-metadata');
    if (localdata) {
      const data = JSON.parse(localdata);
      setData(data);
      fetchAllImages(data);
    }
    const filterdData = await fetch(`${SERVER_URL}models/metadata`).catch(
      (err) => console.error(err)
    );
    const json = await filterdData.json();
    if (!localdata) {
      setData(json);
      fetchAllImages(json);
    }
    localStorage.setItem('tutar-model-metadata', JSON.stringify(json));
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleThumbClick = (e) => {
    const target = e.currentTarget;
    const name = target.dataset.name;
    setCurentModelUrl(`${SERVER_URL}models/get/${name}`);
    const m = data.find((item) => item.name === name);

    // checking if the model is stored in the model store
    if (!md[`models_${m.name}`]) {
      alert('wait till all models are loaded');
      return;
    }
    setCurrentModelData(m);
    const arrBuff = new Uint8Array(md[`models_${m.name}`].blob);
    const blob = new Blob([arrBuff]);

    // checking if the model is stored in the model store
    if (!blob) {
      alert('wait till all models are loaded');
      return;
    }
    const url = URL.createObjectURL(blob);
    setCurentModelUrl(url);
  };

  const handleSearch = (e) => {
    if (e.target.value === '') {
      setFilteredData([]);
      return;
    }
    const filter = data.filter((item) => item.name.includes(e.target.value));
    setFilteredData(filter);
  };

  return (
    <div className='modelPanel'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src='./logo2.png'
          alt=''
          style={{ width: '24px', height: '24px' }}
        />
        <h5
          className='model_heading text-sm'
          style={{ margin: '5px', color: 'whitesmoke' }}>
          Select Models
        </h5>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}>
        <input
          type='text'
          placeholder='Search'
          className='modelSearch text-sm'
          onChange={handleSearch}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '100%',
          }}>
          {filterdData.map((item, i) => (
            <p
              key={i}
              className='searchList text-xs'
              onClick={handleThumbClick}
              data-name={item.name}>
              {item?.name.split('.')[0]}
            </p>
          ))}
        </div>
      </div>
      <div className='model_container'>
        <div className='image_wrapper'>
          {/* {imgBlobArr.length !== 0 &&
            imgBlobArr.map((src, i) => (
              <div key={i} className={`image_container`}>
                <img
                  className='_image'
                  // src={`data:image/jpeg;base64,${item.thumb}`}
                  src={src.url}
                  alt={src.Key}
                  data-name={data[i].name}
                  onClick={handleThumbClick}
                />
              </div>
            ))} */}
          {filterdData.length
            ? filterdData?.map((item, i) => {
                let imgUrl;
                if (images[item.thumbAddr]) {
                  const arrBuff = new Uint8Array(images[item.thumbAddr].blob);
                  const imgBlob = new Blob([arrBuff]);
                  const urlCreator = window.URL || window.webkitURL;
                  imgUrl = urlCreator.createObjectURL(imgBlob);
                }
                return (
                  <div key={i} className={`image_container`}>
                    <img
                      className='_image'
                      src={imgUrl}
                      data-name={item.name}
                      alt={'loading'}
                      onClick={handleThumbClick}
                    />
                  </div>
                );
              })
            : data.map((item, i) => {
                let imgUrl;
                if (images[item.thumbAddr]) {
                  const arrBuff = new Uint8Array(images[item.thumbAddr].blob);
                  const imgBlob = new Blob([arrBuff]);
                  const urlCreator = window.URL || window.webkitURL;
                  imgUrl = urlCreator.createObjectURL(imgBlob);
                }
                return (
                  <div key={i} className={`image_container`}>
                    <img
                      className='_image'
                      src={imgUrl}
                      data-name={item.name}
                      alt={'loading'}
                      onClick={handleThumbClick}
                    />
                  </div>
                );
              })}
        </div>
      </div>
      {curModelAnimLength !== 0 && (
        <div className='anim_selector pb-2'>
          <p className='text-sm'>Select Animation</p>
          {[...Array(curModelAnimLength)].map((x, i) => (
            <button
              className='text-sm bg-mid px-1 rounded'
              key={i}
              onClick={(e) => {
                setCurModelAnim(parseInt(e.target.innerText));
              }}>
              {i}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ModlePanel;
