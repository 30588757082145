import React from 'react';

/**
 *
 *  --
 *  --
 *    This is custom button with reactive styles upon changing the varient
 *  --
 *  --
 *
 *
 * @param {
 * varient : "primary" | "transparent" | "danger"
 * hoverVarient : "underline",
 * active : boolean
 * } props
 * @returns
 */

const Button = (props) => {
  //   console.log(props.onClick);
  const varients = `${
    props.varient === 'primary'
      ? 'bg-green px-5 py-1'
      : props.varient === 'transparent'
      ? 'bg-none'
      : props.varient === 'danger'
      ? 'bg-mid  px-5 py-1 font-bold'
      : 'bg-blue'
  }`;
  const hoverVarient =
    props.hoverVarient === 'underline'
      ? ` before:absolute before:block before:bottom-0 before:left-0 before:w-0 before:h-0.5 before:bg-${
          props.active ? 'lime' : 'text'
        }  before:hover:w-1/2 before:transition-width duration-250`
      : ' hover:bg-lime hover:text-dark hover:scale-110';

  return (
    <button
      {...props}
      className={`${varients} mx-5 my-1 rounded w-fit text-${
        props.active ? 'lime' : 'text'
      } ${props.className} ${hoverVarient}  `}>
      {props.children}
    </button>
  );
};

export default Button;
