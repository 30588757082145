import React from 'react';
import { Link } from 'react-router-dom';
import { BiCameraOff, BiCamera } from 'react-icons/bi';
import { MdFlipCameraIos } from 'react-icons/md';
import { TbFlipVertical } from 'react-icons/tb';
import { VscRecord } from 'react-icons/vsc';

const LeftUI = ({ UIProps, setUIProps, buttonStyle, handleRecordClick }) => {
  return (
    <>
      <div className='text-3xl flex flex-col items-center gap-3'>
        <Link
          to={'/class'}
          className={`${buttonStyle} text-xs !w-full`}>{`< Back`}</Link>
        <button
          className={buttonStyle}
          onClick={() => {
            setUIProps((up) => ({ ...up, camera: !up.camera }));
          }}>
          {UIProps.camera ? <BiCameraOff /> : <BiCamera />}
        </button>
        <button className={buttonStyle}>
          <MdFlipCameraIos
            onClick={() => {
              setUIProps((up) => ({ ...up, backCamera: !up.backCamera }));
            }}
          />
        </button>
        <button className={buttonStyle}>
          <TbFlipVertical
            onClick={() =>
              setUIProps((up) => ({ ...up, cameraFlip: !up.cameraFlip }))
            }></TbFlipVertical>
        </button>
        <button
          className={`${buttonStyle} bg-text w-fit rounded-full text-mid`}
          onClick={handleRecordClick}>
          <VscRecord />
        </button>
      </div>
    </>
  );
};

export default LeftUI;
