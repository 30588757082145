import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

/**
 *
 *  Zustand State Manger
 *  Docs : https://docs.pmnd.rs/zustand/getting-started/introduction
 *
 *  this is an easy to implement state manager for zustand
 *
 */

export const useModelStore = create((set) => ({
  model: {},
  images: {},
  modelObj: {},
  modelData: [],
  currentModelUrl: '',
  currentModelAnim: 0,
  curModelAnimLength: 0,
  modelFormData: {
    Scale: 1,
    X: 0,
    Y: 1,
    Z: 0,
    Intensity: 10,
  },
  currentModelData: {},
  userVideo: false,
  setCurentModelUrl: (url) => {
    set((state) => ({ ...state, currentModelUrl: url }));
  },
  setCurModelAnim: (num) => {
    set((state) => ({ ...state, currentModelAnim: num }));
  },
  setCurModelAnimLength: (num) => {
    set((state) => ({ ...state, curModelAnimLength: num }));
  },
  pushModel: (data) => {
    set((state) => ({
      ...state,
      modelObj: { ...state.modelObj, [data.name]: data },
    }));
  },
  setCurrentModelData: (data) => {
    set((state) => ({ ...state, currentModelData: data }));
  },
  setModelFormData: (key, value) => {
    set((state) => ({
      ...state,
      modelFormData: { ...state.modelFormData, [key]: value },
    }));
  },

  setUserVideo: (b) => {
    set((state) => ({ ...state, userVideo: b }));
  },
  pushImage: (data) => {
    set((state) => ({
      ...state,
      images: { ...state.images, [data.name]: data },
    }));
  },
}));

// displaying the store state in the react dev tools
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useModelStore);
}
