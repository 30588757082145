import { useRef, useState, useEffect } from 'react';
import Modal from '../Utils/Modal';
import PreviewCanvas from '../threeJs/PreviewCanvas';
import { useModelStore } from '../../Store/modelStore';
import 'react-dropdown/style.css';
// import { AllClasses, AllSubjects } from './constants';
import InputBlock from '../UI/InputBlock';
import NewModelForm from './NewModelForm';

/**
 *
 *            -- This is a new model Component --
 * This component gets the input data for the new model upload from the user
 * then it validates all the fields are filled and sends it to the backend
 *
 *
 * @param {socket: webSocket which is connected to the backend} param0
 *
 */

const NewModel = ({ socket }) => {
  const setCurentModelUrl = useModelStore((state) => state.setCurentModelUrl);
  const modelFormData = useModelStore((state) => state.modelFormData);
  // const {s} = modelFormData;
  const image_input_ref = useRef();
  const [modal, setModal] = useState();
  const thumbRef = useRef();
  const mtl_input = useRef();
  const modelInputRef = useRef();
  const [modelData, setModelData] = useState({
    thumbName: '',
    thumb: null,
    file: null,
    name: '',
    DisplayName: '',
    Class: '',
    Subject: '',
    Topic: '',
    animations: [],
    material: null,
    scale: 1,
  });
  const [modelProps, setModelProps] = useState({
    curAnimIndex: 0,
    rotation: { x: 0, y: 0, z: 0 },
    autoRotate: false,
    scale: 1,
    orbitControls: false,
    material: null,
  });

  useEffect(() => {
    console.log(modelData);
  }, [modelData]);

  /**
   *
   *  we are toggeling the socket connection as it triggers multiple times
   *  when we emmit a message from the server
   *
   */

  socket.off('model_received').on('model_received', ({ msg }) => {
    // console.log(msg);
    alert('Model Uploaded in server');
    setModelData({
      thumbName: '',
      thumb: null,
      file: null,
      name: '',
      DisplayName: '',
      Class: '',
      Subject: '',
      Topic: '',
      animations: [],
      material: null,
      Scale: 1,
    });
    setModelProps({
      curAnimIndex: 0,
      rotation: { x: 0, y: 0, z: 0 },
      autoRotate: false,
      Scale: 1,
      orbitControls: false,
      material: null,
    });
    image_input_ref.current.value = null;
    modelInputRef.current.value = null;
    mtl_input.current.value = null;
    setCurentModelUrl('');
    thumbRef.current.style.backgroundImage = null;
  });

  const onFileChange = (e) => {
    console.log(e.target.id);
    if (e.target.id === 'thumbnail-input') {
      // thumbnail input
      const file = e.target.files[0];
      const IURL = URL.createObjectURL(file);
      thumbRef.current.style.backgroundImage = `url(${IURL})`;
      const thumbName = file.name.replace(/ /g, '_');
      setModelData({ ...modelData, thumbName, thumb: file });
    } else {
      // model Input
      const file = e.target.files[0];
      const IURL = URL.createObjectURL(file);
      setCurentModelUrl(IURL);
      const name = file.name.replace(/ /g, '_');
      if (!name.includes('.glb')) {
        console.log(name.split('.')[1]);
        setModelData({ ...modelData, loader: name.split('.')[1] });
      }
      setModelData({ ...modelData, name, file });
      // console.log(name);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(modal);
    console.log('here');

    /**
     *
     * Emmiting the model data to the backend using socket IO
     * need to refactor this and change it to use the FormData
     * to send the model and the data to the backend as we are
     * using the socket IO for only this purpose
     *
     * we can also use Multer for transfering the files to the backend but
     * form data works fine in my opinoin
     *
     */

    socket.emit('_add_model', {
      modelData: {
        ...modelData,
        ...modelFormData,
        animations: null,
      },
    });
    setModal(false);
  };
  console.log('update in new MOdel');
  return (
    <div className='flex flex-col text-text'>
      <h1 className='mt-4 font-medium text-xl'>Add More Model To App</h1>
      <div className='pt-4 h-min-full'>
        <form
          onSubmit={(e) => {
            console.log('here');
            e.preventDefault();
            setModal(true);
          }}
          className='image-form w-full flex flex-col gap-3 mt-4'>
          <div className='flex w-full justify-between gap-3'>
            <div className='flex  flex-col gap-3'>
              <div>
                <p>Add Thumbnail</p>
                <div className='flex'>
                  <div
                    ref={thumbRef}
                    className='bg-accent w-20 h-20 !bg-cover mr-3 !bg-top'></div>
                  <input
                    ref={image_input_ref}
                    type='file'
                    id='thumbnail-input'
                    accept='image/jpeg, image/png,image/jpg,.mp4'
                    required
                    onChange={onFileChange}
                  />
                </div>
              </div>
              <div className='flex flex-col'>
                <p>Add Model</p>
                <input
                  ref={modelInputRef}
                  type='file'
                  id='model-input'
                  accept='.glb'
                  required
                  onChange={onFileChange}
                />
                <span className='p-0 m-0 leading-none ml-2 text-xs  italic'>
                  max-size:12mb
                </span>
              </div>
              <div className='h-56 w-3/4 aspect-video border border-accent'>
                <PreviewCanvas modelProps={modelProps} orbitControls />
              </div>
              <div className='whitespace-pre-line rounded bg-light text-dark'>
                <EmbededCodeGen modelName={modelData.name} />
              </div>
            </div>
            <div className='flex w-1/3 flex-col'>
              <h1>Add Additional Data</h1>
              <div className=' flex flex-col gap-2'>
                <NewModelForm
                  setModelData={setModelData}
                  modelData={modelData}
                />

                <div className='flex flex-col w-full gap-2'>
                  <p>Select Animations :</p>
                  <div className='flex gap-2 flex-wrap w-full'>
                    {modelData.animations?.map((item, i) => {
                      return (
                        <button
                          type='button'
                          className='px-2 bg-mid text-dark'
                          onClick={(e) => {
                            setModelProps((md) => ({
                              ...md,
                              curAnimIndex: parseInt(e.target.innerText),
                            }));
                          }}>
                          {i}
                        </button>
                      );
                    })}
                  </div>
                </div>
                <CustomLightingBlock
                  setModelData={setModelData}
                  modelData={modelData}
                />
              </div>
            </div>
          </div>

          <button type='submit' className='bg-lime w-fit px-4 py-1 rounded-md'>
            Submit
          </button>
        </form>
        {modal && (
          <Modal
            type='Add New Modal'
            setModal={setModal}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default NewModel;

const CustomLightingBlock = (props) => {
  return (
    <div>
      <p>Custom Lighting :</p>
      <div className='flex w-full gap-2'>
        <InputBlock
          label='Active'
          type='checkbox'
          value={false}
          {...props}
          min={1}
          max={4}
        />
        <div className='flex gap-1'>
          <InputBlock label='X' type='number' step={0.05} placeholder='x' />
          <InputBlock label='Y' type='number' step={0.05} placeholder='x' />
          <InputBlock label='Z' type='number' step={0.05} placeholder='x' />
        </div>
      </div>
      <InputBlock label='Intensity' type='range' min={0} max={20} value={0} />
    </div>
  );
};

/**
 *
 * this function create an embed link for the user to display
 * the models on their own site, the source targets and external site
 * which displays the only the modle in full screen. this is still in prototype
 * and can have breaking changes
 *
 * @returns
 */

function EmbededCodeGen({ modelName }) {
  console.log({ modelName });
  return (
    <>
      <div className='p-2'>
        {`<div style='width: 100%; height: 100%'>
          <iframe
            style='width: 100%; height: 100%'
            src='https://embed-prototype.netlify.app/${modelName || ''}'
            frameborder='0'></iframe>
        </div>`}
      </div>
    </>
  );
}
