import React from 'react';
import InputBlock from '../UI/InputBlock';
import { AllClasses, AllSubjects } from './constants';

/**
 *
 * This is just a small form component inside the new model
 * component this takes in only the data and updates it
 * and passes it to the InputBlock component
 *
 */

const NewModelForm = (props) => {
  console.log('log in form');
  return (
    <>
      <InputBlock
        {...props}
        options={AllClasses}
        dropDown={true}
        label='Class'
      />
      <InputBlock
        label='Subject'
        options={AllSubjects}
        dropDown={true}
        {...props}
      />
      <InputBlock label='Topic' {...props} />
      <InputBlock label='DisplayName' {...props} />
      <InputBlock label='Scale' type='number' {...props} />
    </>
  );
};

export default NewModelForm;
