import React from 'react';
import DropDown from 'react-dropdown';
import { useModelStore } from '../../Store/modelStore';

/**
 *
 *  This is a custom Input Block where the higher order component
 *  controls the way it behaves
 *
 * @prop {
 *  label : label for the input block and placeholder
 *  dropDown : boolean
 *  options : options for the drop down menu
 * }
 *
 * @returns
 */

const InputBlock = (props) => {
  const { label, dropDown, options } = props;
  const { modelFormData, setModelFormData } = useModelStore((state) => state);

  return (
    <div className='flex flex-col w-full gap-1'>
      <p>{label} : </p>
      {dropDown ? (
        <DropDown
          options={options}
          onChange={(e) => {
            // setModelData(e.value);
            setModelFormData(label, e.value);
          }}
          placeholder={label}
        />
      ) : (
        <input
          required
          {...props}
          value={modelFormData[label]}
          className={'rounded w-full text-dark px-2 py-1 outline-0'}
          onChange={(e) => {
            console.log(label);
            console.log(e.target.checked);
            setModelFormData(
              label,
              e.target.type === 'checkbox'
                ? e.target.checked
                : props.max
                ? e.target.value > props.max
                  ? props.max
                  : e.target.value
                : e.target.value
            );
            // setModelData((ps) => ({ ...ps, [label]: e.target.value }));
          }}
        />
      )}
    </div>
  );
};

export default InputBlock;
