import { dummyData, dummyModelData } from './dummydata';
import { useState } from 'react';
import Button from '../UI/Button';
import Container from '../UI/Container';
import Flex from '../UI/Flex';
import { FaUserAlt } from 'react-icons/fa';
import { lineGraphData } from './dashboard/chartData';
import LineChart from './dashboard/LineChart';

/**
 *
 *  This component is the dashbord protype
 *  this component displays all the data regarding the user and the modle
 *
 */

const DashBoard = () => {
  const [allUsers] = useState(dummyData);
  const [allModels] = useState(dummyModelData);
  const [displayData, setDisplayData] = useState(null);
  const [currentSelection, setCurrentSelection] = useState('user');

  /**
   * When the user clicks on the details button, the function will grab the data from the data-data
   * attribute and set the display data to that data.
   */
  function handleDetailsClick(e) {
    console.log('handeling details click');
    setDisplayData(
      JSON.parse(e.target.closest('#panel-container').dataset.data)
    );
  }

  return (
    <div className='text-accent h-screen'>
      <Container>
        <h1 className='font-bold'>Dash Board</h1>
        <nav>
          <Button
            varient='transparent'
            hoverVarient='underline'
            className={`!mx-0 `}>
            Dash {'>'}{' '}
          </Button>
        </nav>
        <Flex.Row>
          <Button
            hoverVarient='underline'
            varient='transparent'
            onClick={() => {
              setCurrentSelection('user');
            }}
            active={currentSelection === 'user'}>
            Users
          </Button>
          <Button
            hoverVarient='underline'
            varient='transparent'
            onClick={() => {
              setCurrentSelection('model');
            }}
            active={currentSelection === 'model'}>
            Model
          </Button>
        </Flex.Row>
        <Container className='flex gap-5 flex-wrap'>
          {displayData ? (
            <ItemData data={displayData} setDisplayData={setDisplayData} />
          ) : (
            <>
              {currentSelection === 'user'
                ? allUsers.map((user) => (
                    <UserPanel
                      user={user}
                      key={user.name}
                      handleDetailsClick={handleDetailsClick}
                    />
                  ))
                : allModels.map((user) => (
                    <UserPanel
                      user={user}
                      key={user.name}
                      handleDetailsClick={handleDetailsClick}
                    />
                  ))}
            </>
          )}
        </Container>
      </Container>
    </div>
  );
};

export default DashBoard;

function ItemData({ data, setDisplayData }) {
  const [chartData] = useState(lineGraphData);

  return (
    <div className='flex flex-col w-full '>
      <Flex.Row className={'justify-between'}>
        <h1 className='capitalize text-xl '>{data.name}</h1>
        <Button
          varient='danger'
          onClick={() => {
            setDisplayData(null);
          }}>
          Close
        </Button>
      </Flex.Row>
      <div className='grid grid-cols-4 grid-rows-2 gap-3 h-max '>
        <Container className='bg-teal-300 rounded'>
          <h1 className='text-gray-800 font-bold text-xl'>General Activity</h1>
          <Flex.Col className={'text-light'}>
            <Flex.Row className='flex flex-col text-slate-700 font-medium'>
              <p>
                Active Time : <span>2 hr</span>
              </p>
              <p>
                Premium User : <span>false</span>
              </p>
            </Flex.Row>
          </Flex.Col>
        </Container>
        <Container className='col-span-3 bg-slate-100 rounded '>
          <h1 className='text-gray-800 font-bold text-xl'>Model Activity</h1>
          {chartData && <LineChart chartData={chartData} />}
        </Container>
        <Container className='col-span-4 bg-emerald-600 rounded'>
          <h1 className='font-bold text-gray-800 text-xl'>All Models</h1>
          <Flex.Row className='overflow-x-scroll '>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => (
              <Flex.Col>
                <div className='w-32 h-32 '>
                  <img
                    src='./logo.png'
                    alt=''
                    className='w-full h-full object-contain '
                  />
                </div>
                <p>Model Name</p>
              </Flex.Col>
            ))}
          </Flex.Row>
        </Container>
      </div>
    </div>
  );
}

function UserPanel({ user, handleDetailsClick }) {
  return (
    <div
      data-data={JSON.stringify(user)}
      id='panel-container'
      className=' flex flex-col gap-4 w-40 items-center bg-dark2 p-4 rounded'>
      <div className='flex flex-col gap-2 items-center'>
        <div className='w-8 h-8 '>
          {user.img ? (
            <img
              src={user.img}
              alt='user-profile-img'
              className='object-contain'
            />
          ) : (
            <FaUserAlt size={32} />
          )}
        </div>
        <p className='text-xl capitalize '>{user.name}</p>
      </div>
      <div className='flex flex-col items-center gap-2'>
        <Button
          varient='underline'
          hoverVarient='underline'
          className={'text-sm before:-bottom-1'}
          onClick={handleDetailsClick}>
          View Details
        </Button>
      </div>
    </div>
  );
}
