import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../Components/Utils/NavBar';

const Class = ({ SERVER_URL, data }) => {
  const [classes, setClasses] = useState({ status: true, value: [] });

  useEffect(() => {
    if (!data) return;
    const allClasses = new Set(data.map((i) => i.Class));
    console.log(data);
    setClasses((classes) => ({ ...classes, value: [...allClasses] }));
  }, [data]);

  return (
    <div className='flex flex-col h-screen justify-center relative items-center'>
      <div className='bg-purple w-screen '>
        <NavBar />
      </div>
      <div className='text-text flex flex-col m-4 justify-start items-center w-full h-full md:max-w-5xl'>
        <h1 className='text-2xl'>Select Class</h1>
        <div className='grid grid-cols-2 gap-2 m-auto '>
          {classes.value.length
            ? classes.value.map((item, i) => (
                <div
                  key={i}
                  className='w-full py-2 px-4 rounded bg-accent grid items-center'>
                  <Link
                    className=' w-full text-dark capitalize text-center'
                    to={`class/${item}`}>
                    {item}
                  </Link>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default Class;
