/**
 *
 *  This file contains the dummy data for the dashBoard
 *  prototype,
 *
 */

export const dummyData = [
  {
    name: 'ajay',
    userType: 'student',
    age: '12',
    email: 'ajay@1223.com',
    password: 'abcd',
    verified: 'false',
    modelsInteracted: [
      { modelName: 'model1', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model2', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model3', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model4', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'loki',
    userType: 'faculty',
    age: '32',
    email: 'loki@1223.com',
    password: 'abcd',
    verified: 'true',
    modelsInteracted: [
      { modelName: 'model13', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model34', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model23', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model43', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'amar',
    userType: 'student',
    age: '16',
    email: 'amar@1223.com',
    password: 'abcd',
    verified: 'true',
    modelsInteracted: [
      { modelName: 'model13', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model212', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model323', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model234', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'lata',
    userType: 'student',
    age: '13',
    email: 'lata@1223.com',
    password: 'abcd',
    verified: 'true',
    modelsInteracted: [
      { modelName: 'model1231', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model232', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model1233', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model24', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'manya',
    userType: 'faculty',
    age: '22',
    email: 'manya@1223.com',
    password: 'abcd',
    verified: 'false',
    modelsInteracted: [
      { modelName: 'model1231', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model322', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model3123', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model423', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
];

export const dummyModelData = [
  {
    name: 'model10',
    userType: 'student',
    age: '12',
    email: 'ajay@1223.com',
    password: 'abcd',
    verified: 'false',
    modelsInteracted: [
      { modelName: 'model1', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model2', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model3', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model4', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'model12',
    userType: 'faculty',
    age: '32',
    email: 'loki@1223.com',
    password: 'abcd',
    verified: 'true',
    modelsInteracted: [
      { modelName: 'model13', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model34', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model23', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model43', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'model5',
    userType: 'student',
    age: '16',
    email: 'amar@1223.com',
    password: 'abcd',
    verified: 'true',
    modelsInteracted: [
      { modelName: 'model13', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model212', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model323', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model234', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'model4',
    userType: 'student',
    age: '13',
    email: 'lata@1223.com',
    password: 'abcd',
    verified: 'true',
    modelsInteracted: [
      { modelName: 'model1231', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model232', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model1233', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model24', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'model1',
    userType: 'faculty',
    age: '22',
    email: 'manya@1223.com',
    password: 'abcd',
    verified: 'false',
    modelsInteracted: [
      { modelName: 'model1231', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model322', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model3123', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model423', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'model4322',
    userType: 'faculty',
    age: '22',
    email: 'manya@1223.com',
    password: 'abcd',
    verified: 'false',
    modelsInteracted: [
      { modelName: 'model1231', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model322', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model3123', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model423', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
  {
    name: 'model2342356',
    userType: 'faculty',
    age: '22',
    email: 'manya@1223.com',
    password: 'abcd',
    verified: 'false',
    modelsInteracted: [
      { modelName: 'model1231', rotated: '3', moved: '5', downloaded: '2' },
      { modelName: 'model322', rotated: '4', moved: '1', downloaded: '4' },
      { modelName: 'model3123', rotated: '7', moved: '4', downloaded: '3' },
      { modelName: 'model423', rotated: '12', moved: '14', downloaded: '5' },
    ],
  },
];
