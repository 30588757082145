import React from 'react';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import ModlePanel from '../../Components/Utils/ModelPanel';

const RightUI = (props) => {
  const { UIProps, buttonStyle, setModelProps, modelProps, setUIProps } = props;
  const handleBtnClick = (e) => {
    const btnType = e.target.closest('.btn').getAttribute('data-btntype');

    switch (btnType) {
      case 'UItoggle': {
        setUIProps((up) => ({ ...up, showUI: !up.showUI }));

        break;
      }
      case 'zoom': {
        const zoomType = e.target
          .closest('.btn')
          .getAttribute('data-zoom-type');
        setModelProps((mp) => ({
          ...mp,
          scale: zoomType === 'inc' ? mp.scale + 0.1 : mp.scale - 0.1,
        }));
        break;
      }
      case 'rotation': {
        const axis = e.target.closest('.btn').getAttribute('data-axis');
        const rotType = e.target.closest('.btn').getAttribute('data-rot-type');
        setModelProps((mp) => ({
          ...mp,
          rotation: {
            ...mp.rotation,
            [axis]:
              rotType === 'forward'
                ? mp.rotation[axis] + 0.1
                : mp.rotation[axis] - 0.1,
          },
        }));
        break;
      }
      case 'resetRotation': {
        setModelProps((mp) => ({ ...mp, rotation: { x: 0, y: 0, z: 0 } }));
        break;
      }
      case 'toggleOrbCtrls': {
        setModelProps((mp) => ({
          ...mp,
          orbitControls: !mp.orbitControls,
        }));
        break;
      }
      default:
        console.log('err BTn');
    }
  };

  return (
    <>
      <button
        className={` !block ${buttonStyle}`}
        data-btntype='UItoggle'
        onClick={handleBtnClick}>
        {UIProps.showUI ? <AiFillEyeInvisible /> : <AiFillEye />}
      </button>
      {UIProps.showUI && (
        <div className='flex flex-col gap-2'>
          <div className='flex text-text text-xs  gap-1'>
            <button
              className={buttonStyle + 'p-0 '}
              data-btntype='zoom'
              data-zoom-type='dec'
              onClick={handleBtnClick}>
              <BiZoomOut />
            </button>
            <p>Zoom</p>
            <button
              className={buttonStyle + 'p-0 '}
              data-btntype='zoom'
              data-zoom-type='inc'
              onClick={handleBtnClick}>
              <BiZoomIn />
            </button>
          </div>
          <div className='flex text-text gap-1'>
            <button
              className={buttonStyle + 'text-xs p-0 '}
              data-btntype='rotation'
              data-axis='x'
              data-rot-type='backward'
              onClick={handleBtnClick}>
              -
            </button>
            <p className='text-xs '>RotateX</p>
            <button
              className={buttonStyle + 'text-xs p-0 '}
              data-btntype='rotation'
              data-axis='x'
              data-rot-type='forward'
              onClick={handleBtnClick}>
              +
            </button>
          </div>
          <div className='flex text-text gap-1'>
            <button
              className={buttonStyle + 'text-xs p-0 '}
              data-btntype='rotation'
              data-axis='y'
              data-rot-type='backward'
              onClick={handleBtnClick}>
              -
            </button>
            <p className='text-xs '>RotateY</p>
            <button
              className={buttonStyle + 'text-xs p-0 '}
              data-btntype='rotation'
              data-axis='y'
              data-rot-type='forward'
              onClick={handleBtnClick}>
              +
            </button>
          </div>
          <div className='flex text-text gap-1'>
            <button
              className={buttonStyle + 'text-xs p-0 '}
              data-btntype='rotation'
              data-axis='z'
              data-rot-type='backward'
              onClick={handleBtnClick}>
              -
            </button>
            <p className='text-xs '>RotateZ</p>
            <button
              className={buttonStyle + 'text-xs p-0 '}
              data-btntype='rotation'
              data-axis='z'
              data-rot-type='forward'
              onClick={handleBtnClick}>
              +
            </button>
          </div>
          <button
            className={buttonStyle + 'text-xs '}
            data-btntype='resetRotation'
            onClick={handleBtnClick}>
            Reset Rotation
          </button>
          <button
            className={buttonStyle + 'text-xs '}
            data-btntype='toggleOrbCtrls'
            onClick={handleBtnClick}>
            {modelProps.orbitControls ? 'Off Orb Ctrl' : 'On Orb Ctrl'}
          </button>
          <ModlePanel setModelProps={setModelProps} />
        </div>
      )}
    </>
  );
};

export default RightUI;
