import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { useModelStore } from '../../Store/modelStore';
import gsap from 'gsap';
import { useEffect } from 'react';

/**
 *
 *  This comopnent handles all the 3d functionality with the threeJs
 *  this components takes in model properties as props
 *  and gets more data from the model store and loads the model
 *
 */

const Model = () => {
  const { currentModelData, currentModelAnim, currentModelUrl, modelFormData } =
    useModelStore((state) => state);
  const result = useGLTF(currentModelUrl);

  let mixer;
  if (result.animations.length) {
    mixer = new THREE.AnimationMixer(result.scene);
    const action = mixer.clipAction(result.animations[currentModelAnim]);
    action.play();
  }
  useFrame((_, delta) => {
    mixer?.update(delta);
  });

  useEffect(() => {
    result?.scene?.scale.set(0, 0, 0);
    gsap.to(result.scene.scale, {
      duration: 1.5,
      ease: 'ease-in',
      x: currentModelData.scale || 1,
      y: currentModelData.scale || 1,
      z: currentModelData.scale || 1,
    });
  }, [currentModelUrl, currentModelData.scale, result.scene.scale]);

  console.log(modelFormData);

  return (
    <>
      {currentModelUrl !== '' && result.scene && (
        <primitive
          object={result.scene}
          scale={currentModelData?.scale || modelFormData.Scale || 1}
        />
      )}
    </>
  );
};

export default Model;
