import { Suspense } from 'react';
import Model from './Model';
import { useModelStore } from '../../Store/modelStore';
import { useRef } from 'react';

/**
 *
 * This component acts as wrapper for the model component
 * where we are checking if the for the model URL to exist and then
 * render the Model Componet, And also we are adding some lights
 *
 * We can move this functionality to the higher component and discard
 * this whole wrapper
 *
 */

const ModelWrapper = ({ modelProps }) => {
  const currentModelUrl = useModelStore((state) => state.currentModelUrl);
  const dlRef = useRef();
  return (
    <>
      <ambientLight intensity={0.2} />
      <directionalLight intensity={0.7} ref={dlRef} position={[1, 1, 1]} />
      <Suspense fallback={null}>
        {currentModelUrl !== '' && (
          <Model modelProps={modelProps} Url={currentModelUrl} />
          // <FBX modelProps={modelProps} Url={currentModelUrl} />
        )}
      </Suspense>
    </>
  );
};

export default ModelWrapper;
