import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJs } from 'chart.js/auto';

/**
 *
 *  This is a test component for charts in the dashboard
 *
 */

const LineChart = ({ chartData }) => {
  console.log(chartData);
  console.log(ChartJs);
  const [data] = useState({
    labels: chartData.map((data) => data.year),
    datasets: [
      {
        label: 'Users Gaineed',
        labelColor: 'blue',
        data: chartData.map((data) => data.userGain),
        backgroundColor: ['#67e8f9'],
        borderColor: 'red',
        fill: true,
      },
    ],
  });

  return (
    <div className='chart-container h-64 '>
      {/* <h2 style={{ textAlign: 'center' }}>Line Chart</h2> */}
      <Line data={data} className='text-slate-300' />
    </div>
  );
};

export default LineChart;
